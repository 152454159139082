import React, { FC, ReactNode, useEffect } from 'react'
import {
    View,
    Text,
    ViewStyle,
    TextStyle,
    StyleSheet,
    Platform,
} from 'react-native'
import COLORS from '../../utils/colors'

export type ToastProps = {
    message?: string
    textStyle?: TextStyle
    containerStyle?: ViewStyle
    isVisible?: boolean
    children?: ReactNode
    /* Duration in milliseconds */
    duration?: number
    onClose?: () => void
    testID?: string
    messageTestID?: string
}

const Toast: FC<ToastProps> = (props) => {
    const {
        message,
        containerStyle,
        textStyle,
        isVisible,
        children,
        duration,
        onClose,
        testID,
        messageTestID,
    } = props

    useEffect(() => {
        if (isVisible) {
            const timer = setTimeout(() => {
                onClose?.()
            }, duration || 3000)

            return () => clearTimeout(timer)
        }
    }, [onClose, duration])

    if (!isVisible) return null

    return (
        <View style={[styles.container, containerStyle]} testID={testID}>
            {React.isValidElement(children) ? (
                children
            ) : (
                <Text
                    style={[styles.textStyle, textStyle]}
                    testID={messageTestID}
                >
                    {message}
                </Text>
            )}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 16,
        paddingVertical: 8,
        borderRadius: 8,
        backgroundColor: 'white',
        ...(Platform.OS === 'web'
            ? {
                  boxShadow:
                      '0px 8px 32px rgba(0, 0, 0, 0.06), 0px 4px 8px rgba(0, 0, 0, 0.03)',
              }
            : {
                  shadowColor: '#171717',
                  shadowOffset: { width: -2, height: 4 },
                  shadowOpacity: 0.2,
                  shadowRadius: 3,
              }),
    },
    textStyle: {
        color: COLORS.COLOR_LIGHTBLACK,
    },
})

export default Toast
