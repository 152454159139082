import { StyleSheet } from 'react-native';
import COLORS from '../../utils/colors';

const styles = StyleSheet.create({
    container: {
        padding: 10,
        flexDirection: 'row',
        alignItems: 'center',
    },
    textView: {
        flexDirection: 'column',
        flex: 1,
    },
    text: {
        fontSize: 16,
        fontWeight: '400',
        color: COLORS.COLOR_LISTTEXT,
    },
    subText: {
        fontSize: 14,
        fontWeight: '400',
        color: COLORS.COLOR_SUBTEXT,
    },
    itemSeparator: {
        height: 1,
        backgroundColor: COLORS.COLOR_LIGHTGRAY,
        width: '100%',
    },
    column: {
        flexDirection: 'column',
        flex: 1,
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    marginHorizontal: {
        marginHorizontal: 10,
    },
    iconRightContainer: {
        marginLeft: 'auto',
    },
    childrenContainer: {
        marginTop: 10,
    },
});

export default styles;
