import React, { useState } from 'react'
import { TouchableWithoutFeedback, View } from 'react-native'
import { PortalProvider } from '@gorhom/portal'
import { BuilderContext } from './Builder.context'

const BuilderProvider = ({ children }: { children: React.ReactNode }) => {
    const [newClick, setNewClick] = useState(false)

    const onPress = (e) => {
        setNewClick(!newClick)
    }

    return (
        <BuilderContext.Provider
            value={{
                newClick,
                providerChecker: ({ children }) => <>{children}</>,
            }}
        >
            <TouchableWithoutFeedback
                testID="builder-provider-touchable-feedback"
                onPressIn={onPress}
                style={{
                    backgroundColor: 'transparent',
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                }}
            >
                <View style={{ flex: 1 }}>
                    <PortalProvider>{children}</PortalProvider>
                </View>
            </TouchableWithoutFeedback>
        </BuilderContext.Provider>
    )
}

export default BuilderProvider
