import { StyleSheet } from 'react-native'
import COLORS from '../../utils/colors'

export const styles = StyleSheet.create({
    parentContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    parentContainerVertical: {
        alignItems: 'center',
    },
    stepContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        flexGrow: 1,
    },
    stepIconContainer: {
        borderRadius: 100,
        backgroundColor: '#94A3B8',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        height: 32,
        width: 32,
    },
    stepActive: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
    },
    step: {
        fontFamily: 'Arial',
        fontWeight: '700',
        color: COLORS.COLOR_WHITE,
    },
    activeText: {
        color: COLORS.COLOR_COOLGRAY,
        marginLeft: 8,
    },
    text: {
        fontFamily: 'Arial',
        fontWeight: '700',
    },
    futureText: {
        color: COLORS.COLOR_GRAY,
        marginLeft: 8,
    },
    imageStyle: {
        height: 16,
        width: 16,
        resizeMode: 'stretch',
        overflow: 'hidden',
    },
    separator: {
        width: 25,
        height: 3,
        borderRadius: 20,
        backgroundColor: COLORS.COLOR_GRAY_PLACEHOLDER,
        marginHorizontal: 4,
    },
})
